body {
	/* background: url("https://i.imgur.com/FDRlpoY.png"); */
	background-repeat: no-repeat;
	background-size: cover;
	user-select: none;
	overflow-x: hidden;
}
#background-video {
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: -1;
}
