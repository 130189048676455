.bShadow {
	box-shadow: 0px 0px 15px rgb(239 68 68);
	transition: all 0.6s;
}

.b2Shadow {
	box-shadow: 0px 0px 15px rgb(59 130 246);
	transition: all 0.6s;
}
.b2Shadow:hover {
	transform: scale(1.1);
}

.pulse {
	animation: pulse ease-in-out 1s alternate infinite;
}
@keyframes pulse {
	to {
		transform: scale(1.08);
	}
}
