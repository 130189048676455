@tailwind base;
@tailwind components;
@tailwind utilities;

.anim-scale {
	animation: scal 0.2s ease-in-out;
}
.anim-scale-left {
	transform: scale(1.5);
	animation: scal-left 0.3s ease-in-out forwards;
}
.anim-scale-reverse {
	animation: scal-reverse 0.8s ease-in-out;
}
@keyframes scal {
	from {
		transform: scale(5) translateX(-25px);
	}
	to {
		transform: scale(1) translateX(0px);
	}
}
@keyframes scal-left {
	to {
		transform: scale(1);
	}
}

@keyframes scal-reverse {
	from {
		transform: scale(0.5);
	}
	to {
		transform: scale(1);
	}
}
